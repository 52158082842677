.restriction{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    &__heading{
        color: #363A45;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        padding: 12px 12px 0px 12px;
    }
    &__sub-heading{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #515767;
        padding: 20px 10% 20px 10%;
    }
}