* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Poppins';
}
.Error--boundary {
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100lvh;
	&__text-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 8px;
	}
	&__text-heading {
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		text-align: center;
		color: #363A45;
		margin-top: 24px;
	}
	&__text-subheading {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		color: #7C8398;
		margin-bottom: 24px;
	}
}
