.liveness-wrapper {
	display: flex;
	justify-content: center;
	overflow: hidden;
}

video {
	clear: both;
	display: flex;
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	margin-bottom: 20px;
}

.videoView {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.videoView,
.blend-shapes {
	overflow-y: hidden;
	position: relative;
	margin: 2%;
	margin-bottom: 12px;
	cursor: pointer;
}

.videoView p,
.detectOnClick p {
	position: absolute;
	padding: 5px;
	background-color: var(--color-Brunswick);
	color: #fff;
	border: 1px dashed rgba(255, 255, 255, 0.7);
	z-index: 2;
	font-size: 12px;
	margin: 0;
}

.liveness-video {
	position: relative;
}

.highlighter {
	background: rgba(0, 255, 0, 0.25);
	border: 1px dashed var(--color-bg-110-light);
	z-index: 1;
	position: absolute;
}

.canvas {
	z-index: 1;
	position: absolute;
	pointer-events: none;
}

.output_canvas {
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	position: absolute;
	left: 0;
	top: 0;
	overflow: hidden;
}

.detectOnClick {
	z-index: 0;
}

.detectOnClick img {
	width: 100%;
}

.blend-shapes-item {
	display: flex;
	align-items: center;
	height: 20px;
}

.blend-shapes-label {
	display: flex;
	width: 120px;
	justify-content: flex-end;
	align-items: center;
	margin-right: 4px;
}

.blend-shapes-list {
	overflow: scroll;
	max-height: 500px;
	border: 1px solid var(--color-black);
	padding: 20px 80px;
}

.blend-shapes-value {
	display: flex;
	height: 16px;
	align-items: center;
	background-color: var(--color-Brunswick);
}

// Information gesture CSS

.gesture_information {
	width: 100%;
	padding: 4px;
	position: absolute;
	bottom: -20px;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 1;
	display: grid;
	gap: 10px;
	justify-items: center;
}

.geture-type-label {
	display: flex;
	justify-content: center;
	border-radius: 4px;
	padding: 4px 8px;
	background-color: var(--color-light-canvas-background);
	color: var(--color-bg-100-light);
	font-weight: 700;
	font-size: var(--font-size-3);
	letter-spacing: 1px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.gesture-progress-wrapper {
	height: 8px;
	width: 192px;
	background-color: var(--color-border-90-light);
	position: relative;
	border-radius: 4px;
}

.gesture-progress {
	position: absolute;
	top: 0;
	left: 0;
	height: 8px;
	border-radius: 4px;
}

.gesture-progress-min {
	@extend .gesture-progress;
	background-color: var(--color-tart-orange);
}

.gesture-progress-mid {
	@extend .gesture-progress;
	background-color: var(--color-accent-dark);
}

.gesture-progress-max {
	@extend .gesture-progress;
	background-color: var(--color-dark-green);
}

.gesture-type-box {
	position: relative;
	&__video {
		transform: none;
	}
}
.video-loads-loader{
	position:absolute ;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.ref-video-label {
	position: absolute;
	right: 12px;
	top: 12px;
	display: flex;
	justify-content: center;
	border-radius: 4px;
	padding: 4px 8px;
	background-color: var(--color-light-canvas-background);
	color: var(--color-bg-100-light);
	font-weight: 500;
	font-size: var(--font-size-2);
	letter-spacing: 1px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.ref-video-step-count {
	position: absolute;
	bottom: 24px;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	border-radius: 4px;
	padding: 4px 8px;
	background-color: var(--color-light-canvas-background);
	color: var(--color-bg-100-light);
	font-weight: 700;
	font-size: var(--font-size-3);
	letter-spacing: 1px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.loading-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	&__box {
		padding: 24px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	&__heading {
		text-align: center;
		font-weight: 600;
		font-size: 16px;
		line-height: var(--line-height-3);
		color: var(--color-gray-very-dark);
	}
	&__message{
		text-align: center;
		font-weight: 400;
		font-size: 12px;
		line-height: var(--line-height-2);
		align-items: center;
		color: var(--color-label-dark-200);
		margin: 12px;
	}
}
