.LocationPermission {
	display: flex;
	flex-direction: column;
	gap: 32px;
	text-align: center;
	padding: 20px 60px;
	&__img {
		height: 120px;
	}
	&__heading {
		color: #363a45;
		margin-bottom: 4px;
		font-weight: 500;
	}
	&__steps-continer {
		text-align: left;
		color: #515767;
		ul {
			display: flex;
			flex-direction: column;
			gap: 8px;
			list-style: none;
			font-size: 13px;
		}
	}
	@media (max-width: 768px) {
		padding: 0;
		&__modal {
			padding: 16px;
		}
	}
}