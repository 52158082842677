.verification {
  &__success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    align-items: center;
    min-width: 300px;
  }
  &__success-img{
    width: 150px;
  }
  &__success_img-wrapper{
    height: 150px;
    width: 150px;
  }
  &__success-message {
    font-size: var(--font-size-6);
    font-weight: 500;
    text-align: center;
    line-height: var(--line-height-7);
    margin-bottom: var(--margin);
  }
  &__success-description {
    font-size: var(--font-size-3);
    font-weight: 500;
    text-align: center;
    line-height: var(--line-height-3);
  }
}
