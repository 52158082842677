.Face-Result-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
        &__box {
            padding: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        &__heading {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            margin-top: 12px;
            line-height: var(--line-height-3);
            color: var(--color-gray-very-dark);
        }
        &__message{
            text-align: center;
            font-weight: 400;
            font-size: 12px;
            line-height: var(--line-height-2);
            color: var(--color-label-dark-200);
            margin: 12px;
        }
    
}