.PaymentAcceptance--container {
	padding: 4px;
	height: 99vh;

	&__Decline {
		width: 100%;
		height: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 4px;
		display: flex;
		&-header {
			align-self: stretch;
			text-align: center;
			color: #363a45;
			font-size: 24px;
			font-family: Poppins;
			font-weight: 600;
			word-wrap: break-word;
		}
		&-subHeader {
			align-self: stretch;
			text-align: center;
			color: #7c8398;
			font-size: 14;
			font-family: Poppins;
			font-weight: 400;
			word-wrap: break-word;
		}
	}
}
.btn-width {
	width: 100%;
}
.PaymentAcceptance {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	padding-bottom: calc(var(--base-padding) * 0.5);
	&__footer__actions {
		display: flex;
		gap: 4px;
		align-items: flex-start;
	}
	&__footer__actions-wrapper {
		padding: 8px 0px;
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
	&__checkbox {
		min-width: 22px;
		min-height: 22px;
		cursor: pointer;
		display: grid;
		place-items: center;
		border-radius: 0px;
	}
	&__action__content {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		color: #7c8398;
	}
	&__terms {
		color: var(--color-primary-dark-blue);
		font-weight: 600;
		cursor: pointer;
		padding-left: 6px;
	}

	&__header {
		display: flex;
		flex-direction: column;
		gap: 8px;
		&__title {
			font-weight: 600;
			font-size: var(--font-size-4);
			line-height: calc(var(--line-height-2) * 1.75);
			color: var(--color-text-90-light);
			letter-spacing: 0;
		}
		&__sub-title {
			font-weight: 400;
			font-size: var(--font-size-3);
			line-height: 16px;
			color: var(--color-text-70-light);
		}
	}
	&__card {
		position: relative;
		display: grid;
		place-items: center;
		max-width: 450px;
		text-align: center;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
		background: var(--color-bg-90-light);
		border-radius: calc(var(--base-border-radius) * 0.5);
		margin-top: calc(var(--base-margin) * 1.5);
		&__image {
			height: 200px;
			width: 100%;
		}
		&__doller_icon_wrapper {
			position: absolute;
			top: -20px;
			font-size: var(--font-size-7);
			width: 48px;
			height: 48px;
			box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
			border-radius: 50%;
			display: grid;
			place-items: center;
			color: var(--color-primary-light);
			background: var(--color-bg-100-light);
		}
		&__investment_wrapper {
			position: absolute;
		}
		&__amount_paid {
			font-size: calc(var(--font-size-6) * 2);
			font-weight: 600;
			color: var(--color-text-90-light);
			&--dollorUsd {
				font-size: 20px;
				color: #6c7489;
				font-weight: 700;
				padding-left: 12px;
			}
		}
		&__amount_paid_text {
			font-weight: 500;
			font-size: 12px;
			color: var(--color-text-60-light);
		}
	}

	.PaymentAcceptance--Card-Details {
		&__Details {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			&--keys {
				color: var(--color-label-dark-200);
				font-size: 14px;
				font-weight: 400;
				word-wrap: break-word;
				width: 40%;
				height: 20px;
				align-items: center;
				gap: 2px;
				display: flex;
				padding-left: 16px;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
			&--values {
				width: 60%;
				font-size: 14px;
				font-weight: 500;
				word-wrap: break-word;
				text-align: end;
				color: var(--color-text-light-100);
				font-family: Poppins, sans-serif;
				padding-right: 16px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.Card-Details {
		width: 100%;
		max-width: 450px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		.PaymentAcceptance--btn {
			display: flex;
			gap: 12px;
			width: 100%;
			justify-content: center;
			position: sticky;
			bottom: 0;
			background-color: white;
			&--accept {
				width: 100%;
			}
			&--decline {
				width: 100%;
				.button {
					&__filled--primary {
						color: var(--color-text-red);
						background: rgba(211, 34, 34, 0.09);
					}
				}
			}
		}
	}
}
