.Offline--container {
	width: 384px;
	height: 100vh;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 12px;
	&__image {
		width: 192px;
		height: 192px;
	}
	&__text {
		font-size: 20px;
		font-weight: 600;
		line-height: 32px;
		color: var(--color-black);
	}
	&__desc {
		color: var(--color-text-130-light);
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		text-align: center;
	}
	&__retrybtn {
		width: 120px;
		height: 44px;
		padding: 12px 16px 12px 16px;
		text-align: center;
		font-size: 14px;
		line-height: 20px;
		border: none;
		color: var(--color-bg-100-light);
		cursor: pointer;
	}
}

@media only screen and (max-width: 480px) {
	.Offline--container {
		width: 100%;
		padding: 20px;
	}
  }
