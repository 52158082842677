.LivenessInformation--container {
	display: flex;
	padding: 16px 0px;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	flex: 1 0 0;
	align-self: stretch;
	&__loader-wrapper{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
	}
	&__loader-wrapper-box{
		display: flex;
		justify-content: center;
		align-items: center;
		min-width:380px ;
		min-height: 380px;
	}

	&__content {
		flex: 1;
		padding-right: 20px;
		padding-left: 10px;
		max-width: 600px;

		&__heading {
			color: #000;
			font-family: poppins;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 40px;	
		}

		&__subheading {
			color: var(--color-text-light-80, #515767);
			font-family: poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
		}

		&__list {
			padding-left: 20px;
			&__list-item {
				list-style-type: disc;
				color: var(--color-text-light-80, #515767);
				font-family: poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}
		}
	}

	&__imageinfo {
		width: 300px;
		height: 300px;
	    margin: 0 auto 2px;
		object-fit: cover;
	}
	@media screen and (max-width: 375px) {
		&__imageinfo {
			width: 250px;
			height: 250px;
			margin: 0 auto 15px;
		}
	}
}

.LivenessInformation__button--info {
	display: flex;
	justify-content: center;
	.button {
		width: 300px;
	}
}

@media screen and (max-width: 420px) {
	.LivenessInformation__button--info {
		display: flex;
		justify-content: center;
	}
}
.btn-begin-liveness{
	background-color: white;
	padding: 4px;
	position: sticky;
	bottom: 0;
	border: none;
}