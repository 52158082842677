.terms {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
    padding: 8px;
    &__button-width{
        width: 200px;
    }
    &__sticky-term-policy {
        z-index: 1;
        position: fixed;
        right: 0;
        top: 0;
        font-size: 42px;
    }
    &__sticky-button-close {
        position: sticky;
        bottom: 0px;
    }
    
}
.privacy-container {
	padding: 12px 0px 0px 0px;
	height: 100%;
	width: 100%;
}
.privacy-frame {
	width: 100%;
	height: 100vh;
    padding-bottom: 24px;
	border: none;
}

@media only screen and (max-width: 600px) {
    .terms__button-width{
        width: 100%;
    }
    
  }
 