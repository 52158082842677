.CameraAccessRequired--container {
	width: 100%;
	height: 90vh;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 12px;

	&__imageheading{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 8px;
		&__image {
			width: 192px;
			height: 172px;
		}
		&__mainheading {
			font-size: 20px;
			font-weight: 600;
			line-height: 32px;
			color: var(--color-black);
			text-align: center;
		}
		&__subheading {
			color: var(--color-text-130-light);
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
		}
		@media screen and (max-width: 768px) {
			&__subheading {
			  padding:15px; // Adjust this value for very small screens if needed
			}
		}
	}

&__stepdetails {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding-left: 82px;

		&__follow {
			width: 100%;
			color: var(--color-text-90-light);
			font-size: 14px;
			font-family: 'Poppins';
			font-weight: 500;
			line-height: 40px;
			word-wrap: 'break-word';
		}
		&__list {
			color: #6C7489;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			padding-left: 20px;
}
		
	}
	@media screen and (max-width: 768px) {
		&__stepdetails {
		  padding-left:15px; // Adjust this value for very small screens if needed
		}
	}
}
